import React, { useEffect, useState } from 'react';
import { Card, Select, Autocomplete, Group } from '@mantine/core';
import { supabase } from "../supabaseClient";
import { IconCalendar } from '@tabler/icons-react';
import { rem } from '@mantine/core';

const FiltrosPolizasNuevas = ({ setFilters, filters }) => {
    const icon = <IconCalendar style={{ width: rem(18), height: rem(18) }} stroke={1.5} />;

    const [periodos, setPeriodos] = useState([]);
    const [meses, setMeses] = useState([
        { label: 'Enero', value: 'Enero' },
        { label: 'Febrero', value: 'Febrero' },
        { label: 'Marzo', value: 'Marzo' },
        { label: 'Abril', value: 'Abril' },
        { label: 'Mayo', value: 'Mayo' },
        { label: 'Junio', value: 'Junio' },
        { label: 'Julio', value: 'Julio' },
        { label: 'Agosto', value: 'Agosto' },
        { label: 'Septiembre', value: 'Septiembre' },
        { label: 'Octubre', value: 'Octubre' },
        { label: 'Noviembre', value: 'Noviembre' },
        { label: 'Diciembre', value: 'Diciembre' }
    ]);
    const [aseguradoras, setAseguradora] = useState([]);
    const [tiposeguro, setTiposeguro] = useState([]);

    useEffect(() => {
        const fetchPeriodos = async () => {
            try {
                const { data: periodosData, error: periodosError } = await supabase
                    .from('vista_polizas4')
                    .select('periodo')
                    .order('periodo', { ascending: true });

                if (periodosError) {
                    throw periodosError;
                }

                const uniqueYears = new Set();
                periodosData.forEach(periodo => uniqueYears.add(periodo.periodo));

                const periodosOptions = Array.from(uniqueYears).map(year => ({
                    label: year.toString(),
                    value: year.toString()
                }));

                setPeriodos(periodosOptions);

            } catch (error) {
                console.error("Error al obtener los periodos:", error);
            }
        };

        const fetchAseguradorasData = async () => {
            try {
                const { data: polizasData, error: polizasError } = await supabase
                    .from('cat_aseguradoras')
                    .select('nombre_aseguradora')
                    .order('nombre_aseguradora', { ascending: true });

                if (polizasError) {
                    throw polizasError;
                }

                const aseguradorasOptions = polizasData.map((aseguradora) => ({
                    label: aseguradora.nombre_aseguradora,
                    value: aseguradora.nombre_aseguradora
                }));

                setAseguradora(aseguradorasOptions);
            } catch (error) {
                console.error('Error al obtener las aseguradoras:', error.message);
            }
        };

        const fetchTiposeguroData = async () => {
            try {
                const { data: tiposeguroData, error: tiposeguroError } = await supabase
                    .from('cat_tipos_seguros')
                    .select('nombre_tipo_seguro')
                    .order('nombre_tipo_seguro', { ascending: true });

                if (tiposeguroError) {
                    throw tiposeguroError;
                }

                const tiposeguroOptions = tiposeguroData.map((tiposeguro) => ({
                    label: tiposeguro.nombre_tipo_seguro,
                    value: tiposeguro.nombre_tipo_seguro
                }));

                setTiposeguro(tiposeguroOptions);
            } catch (error) {
                console.error('Error al obtener las tiposeguro:', error.message);
            }
        };

        fetchPeriodos();
        fetchAseguradorasData();
        fetchTiposeguroData();
    }, []);

    return (
        <div style={{
            //display: 'flex',
            //flexWrap: 'wrap',
            marginBottom: '10px',
            //justifyContent: 'center', // Centra horizontalmente
            //alignItems: 'center', // Centra verticalmente
        }}>
            <Group style={{ display: 'flex', justifyContent: 'flex-start' }}>

                    <Select
                        label="Año"
                        placeholder="Selecciona año"
                        data={periodos}
                        value={filters.periodo}
                        onChange={(value) => setFilters({ ...filters, periodo: value })}
                        name="periodo"
                        allowDeselect={false}
                        style={{ width: '300px', maxWidth: '200px' }}
                        />
                    <Select
                        label="Mes"
                        placeholder="Selecciona mes"
                        data={meses}
                        value={filters.mes}
                        onChange={(value) => setFilters({ ...filters, mes: value })}
                        name="mes"
                        allowDeselect={false}
                        style={{ width: '300px', maxWidth: '200px' }}
                        />
                    <Autocomplete
                        label="Aseguradora"
                        placeholder="Selecciona Aseguradora"
                        data={aseguradoras}
                        value={filters.aseguradora}
                        onChange={(value) => setFilters({ ...filters, aseguradora: value })}
                        name="aseguradora"
                        //style={{ flex: '1 1 auto', marginRight: '5px' }}
                        style={{ width: '300px', maxWidth: '300px' }}

                    />
                    <Autocomplete
                        label="Tipo seguro"
                        placeholder="Selecciona tipo seguro"
                        data={tiposeguro}
                        value={filters.tipo_seguro}
                        onChange={(value) => setFilters({ ...filters, tipo_seguro: value })}
                        name="tipo_seguro"
                        style={{ width: '300px', maxWidth: '300px' }}
                        />

                </Group>


        </div>
    );
};

export default FiltrosPolizasNuevas;
